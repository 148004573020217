<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <detail-sanitaire
      :animal="animal"
      :create-by="createBy"
      :create-at="createAt"
    >
      <template slot="header">
        <h4 />
      </template>
      <template slot="content">
        <div class="row info">
          <div class="col-12">
            <h6> Activités </h6>
            <p>{{ toilettage.activities }}</p>
          </div>
        </div>
        <div class="row info">
          <div class="col-6">
            <h6> Date toilettage </h6>
            <p> {{ toilettage.dateToilettage|systemDateTime }} </p>
          </div>
          <div class="col-6">
            <h6> Date Rappel </h6>
            <p> {{ toilettage.dateRappel|systemDateTime }} </p>
          </div>
        </div>
        <div class="row info">
          <div class="col-12">
            <h6>Information supplementaire</h6>
            {{ toilettage.info }}
          </div>
        </div>
      </template>
    </detail-sanitaire>
  </div>
</template>
  
  <script>
  import navbar from '../../../components/navbar.vue';
  import DetailSanitaire from '../../../components/sanitaire/detailSanitaire.vue'
  import { mapGetters } from 'vuex';
  export default {
      components: {navbar, DetailSanitaire},
      data(){
          return {
              navbarItems: [
                  { libelle: 'Sanitaire' },
                  { libelle: 'Toilettage', route: 'toilettage' },
                  { libelle: 'Détail' }
              ],
              pageIcon: 'la-bath',
              pageTitle: 'Détail',
              pageDescription: 'Toilettage détail',
              toilettage: null
          }
      },
      watch: {
        toilettages: {
              handler(){
                  this.load()
              },
              deep: true
          }
      },
      mounted(){
          this.load()
      },  
      methods: {
          load(){
              if(this.$route.params.uid !== null){
                  this.toilettage =  this.toilettages.find(item => item.uid === this.$route.params.uid)
              }else{
                  this.toilettage =  null
              }
              
          }
      },
      computed: {
          ...mapGetters({
              toilettages: 'sanitaire/toilettages',
              motifs: 'sanitaire/motifConsultations',
              modes: 'sanitaire/modeVies',
              aliments: 'identification/aliments'
          }),
          hasToilettage() { return this.toilettage !== null && this.toilettage !== undefined},
          animal(){
              if(this.hasToilettage) return this.toilettage.animal
              return null
          },
          createBy(){
              return (this.hasToilettage)? this.toilettage.createBy:null
          },
          createAt(){
              return (this.hasToilettage)? this.toilettage.createdAt:null
          },
          /**motif(){
              if(this.hasAnamnese) {
                  let m = this.motifs.find(item => item.uid === this.anamnese.motif)
                  if(m) return m.libelle
              }
              return '-'
          },
          mode(){
              if(this.hasAnamnese){
                  let m = this.modes.find(item => item.uid === this.anamnese.modeVie)
                  if(m) return m.libelle
              }
              return '-'
          },
          als(){
            let result = []
            if(this.hasAnamnese){
              JSON.parse(this.anamnese.alimentation).forEach(item => {
                let al = this.aliments.find(i => i.uid === item)
                if(al) result.push(al.libelle)
              })
            }
            return result
          }*/
  
      }
  
  }
  </script>
  
  <style>
  
  </style>